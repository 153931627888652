import axios from "axios";
// import { DailyLearningAnswer } from '@/types/DailyLearningAnswer'
import { LearningCourse } from '@/types/learning_course'
import { LearningCourseQuestion } from '@/types/LearningCourseQuestion'
import { LearningCourseHistory } from '@/types/learning_course_history'
import { LearningForm } from '@/types/learningForm'

export class LearningCourseRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;

  private url: string;
  constructor(private projectId: number) {
    this.url = process.env.VUE_APP_DOMAIN + 'v1/projects/' + projectId + '/learning_courses/'
  }




  async getList(params: { [key: string]: string | number }): Promise<LearningCourse[]> {
    params.project_id = this.projectId;
    return axios.get(this.url, { params: params }).then((response) => {
      return response.data.learning_courses;
    });
  }
  async get(learning_course_id: number): Promise<LearningCourse> {
    return axios.get(this.url + learning_course_id, { params: { project_id: this.projectId } }).then((response) => {
      return response.data;
    });
  }
  async getQuestion(learning_course_id: number): Promise<LearningCourseQuestion> {

    return axios.get(this.url + learning_course_id + "/question", { params: { project_id: this.projectId } }).then((response) => {
      return response.data;
    });
  }
  async getRandQuestion(learningType: string, categoryId: number): Promise<LearningForm> {
    return axios.get(this.url + "/rand_learning_form", { params: { project_id: this.projectId, learning_type: learningType, question_category_id: categoryId } }).then((response) => {
      return response.data;
    });
  }
  async existsUnanswered(learning_course_id: number): Promise<boolean> {
    return axios.get(this.url + learning_course_id + "/exists_unanswered", { params: { project_id: this.projectId } }).then((response) => {
      return response.data.exists;
    });
  }

  async getHistory(learning_course_id: number, user_id?: number): Promise<LearningCourseHistory> {
    return axios.get(this.url + learning_course_id + "/history", { params: { project_id: this.projectId, user_id: user_id } }).then((response) => {
      return response.data;
    });
  }
  async add(name: string, category_id: number, learning_forms: LearningForm[]): Promise<LearningCourse> {
    return axios.post(this.url, { project_id: this.projectId, name: name, category_id: category_id, learning_forms: learning_forms }).then((response) => {
      return response.data
    })
  }
  async update(learning_course_id: number, name: string, learning_forms: LearningForm[]): Promise<LearningCourse> {
    return axios.put(this.url + learning_course_id, { project_id: this.projectId, name: name, learning_forms: learning_forms }).then((response) => {
      return response.data
    })
  }

  async changeCategory(learning_course_id: number, learning_cateogry_id: number) {
    return axios.post(this.url + learning_course_id + '/change_category', {learning_category_id: learning_cateogry_id});
  }

  async deleteLearningCourse(learning_course_id: number): Promise<void> {
    return axios.delete(this.url + learning_course_id, { params: { project_id: this.projectId } }).then((response) => {
    })
  }
}
