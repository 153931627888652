import { ref, watch, computed } from "vue";
import { ElMessage } from "element-plus"
import { LearningCategory } from '@/types/learning_category'
import { LearningCategoryRepository } from '@/repositories/LearningCategoryRepository'
import { useRoute } from 'vue-router'

export const LearningCategoryLogic = () => {
    const route = useRoute();
    const learningCategoryErrors = ref("");
    const learningCategoryLoading = ref(false)
    const learningCategories = ref<LearningCategory[]>([])
    const projectId = computed(() => Number(route.params.project_id))

    const searchLearningCategory = (l_categories: LearningCategory[], search_id: number): LearningCategory | null => {
        for (const l_category of l_categories) {
            if (l_category.id == search_id) {
                return l_category
            }
            if (l_category.children && l_category.children.length > 0) {
                const ret = searchLearningCategory(l_category.children, search_id);
                if (ret != null) {
                    return ret;
                }
            }
        }
        return null;
    };

    const loadLearningCategories = async () => {
        learningCategoryLoading.value = true;
        try {
            const repository = new LearningCategoryRepository(projectId.value);
            learningCategories.value = await repository.getList();
            learningCategoryLoading.value = false;
        } catch {
            learningCategoryErrors.value = "カテゴリ取得時にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    };
    const addLearningCategory = async (parentId: number, name: string) => {
        learningCategoryLoading.value = true;
        try {
            const repository = new LearningCategoryRepository(projectId.value);
            learningCategories.value = await repository.add(parentId, name);
            learningCategoryLoading.value = false;
        } catch {
            learningCategoryErrors.value = "カテゴリ追加にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }
    const updateLearningCategory = async (targetId: number, name: string) => {
        learningCategoryLoading.value = true;
        try {
            const repository = new LearningCategoryRepository(projectId.value);
            const learningCategory: LearningCategory = await repository.update(targetId, name);

            const target_learning_category = searchLearningCategory(learningCategories.value, targetId);
            if (target_learning_category) {
                target_learning_category.name = learningCategory.name;
            }

            learningCategoryLoading.value = false;
        } catch {
            learningCategoryErrors.value = "更新にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }

    const deleteLearningCategory = async (targetId: number) => {
        learningCategoryLoading.value = true;
        const repository = new LearningCategoryRepository(projectId.value);
        try {
            learningCategories.value = await repository.delete(targetId)
            learningCategoryLoading.value = false;
        } catch {
            learningCategoryErrors.value = "削除時にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }
    const sortLearningCategory = async (targetId: number, dependId: number, type: string) => {
        learningCategoryLoading.value = true;
        const repository = new LearningCategoryRepository(projectId.value);
        try {
            learningCategories.value = await repository.sort(targetId, dependId, type)

            learningCategoryLoading.value = false;
        } catch {
            learningCategoryErrors.value = "並び替え時にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }
    watch(learningCategoryErrors, () => {
        if (learningCategoryErrors.value == "") {
            return;
        }
        ElMessage({
            showClose: true,
            message: learningCategoryErrors.value,
            type: 'error'
        });
        learningCategoryErrors.value = "";
    })
    const learningCategoryStateReturn = () => {
        return {
            loadLearningCategories,
            addLearningCategory,
            updateLearningCategory,
            deleteLearningCategory,
            sortLearningCategory,
            learningCategories,
            learningCategoryErrors,
            learningCategoryLoading

        }
    }

    return {
        learningCategoryStateReturn,
        ...learningCategoryStateReturn()
    }
}


