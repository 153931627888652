import { ref, watch } from "vue";
import { ElMessage } from "element-plus"
import axios from 'axios';
import { LearningCourse } from "@/types/learning_course";
import { LearningCourseRepository } from '@/repositories/LearningCourseRepository'
export const LearningCourseListLogic = () => {

    const learningCourseListErrors = ref("");
    const learningCourseListLoading = ref(false)
    const noMore = ref(false)
    const learningCourses = ref<LearningCourse[]>([])


    const getLearningCourses = async (projectId: number, category_id: number, clear = false) => {
        learningCourseListLoading.value = true

        if (clear) {
            learningCourses.value = [];
            noMore.value = false;
        }
        const params: { [key: string]: string | number } = {}

        if (category_id > 0) {
            params.learning_category_id = category_id
        }
        if (learningCourses.value.length > 0) {
            params.offset = learningCourses.value.length;
        }
        const repository: LearningCourseRepository = new LearningCourseRepository(projectId);
        try {
            const result = await repository.getList(params);
            learningCourses.value.push(...result);
            learningCourseListLoading.value = false;
            if (result.length == 0) {
               noMore.value = true 
            }

        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                learningCourseListErrors.value = `取得に失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
            }
        }


    };

    const LearningCourseListStateReturn = () => {
        return {
            learningCourseListErrors,
            learningCourseListLoading,
            learningCourses,
            noMore,
            getLearningCourses
        }
    }
    watch(learningCourseListErrors, () => {
        if (learningCourseListErrors.value == "") {
            return;
        }
        ElMessage({
            showClose: true,
            message: learningCourseListErrors.value,
            type: 'error'
        });
        learningCourseListErrors.value = "";
    })
    return {
        LearningCourseListStateReturn,
        ...LearningCourseListStateReturn()
    }
}


