import axios from "axios";
import { LearningCategory } from '@/types/learning_category'

export class LearningCategoryRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  constructor(private projectId: number) { }

  async getList(): Promise<LearningCategory[]> {
    return axios.get(LearningCategoryRepository.DOMAIN + "v1/learning_categories/", { params: { project_id: this.projectId } }).then((response) => {
      return response.data.learning_categories;
    });
  }
  async update(targetId: number, name: string): Promise<LearningCategory> {
    return axios.put(LearningCategoryRepository.DOMAIN + "v1/learning_categories/" + targetId, { project_id: this.projectId, learning_category: { name: name } }).then((response) => {
      return response.data.learning_category
    })
  }
  async add(parentId: number, name: string,): Promise<LearningCategory[]> {
    return axios.post(LearningCategoryRepository.DOMAIN + "v1/learning_categories", { project_id: this.projectId, learning_category: { parent_id: parentId, name: name } }).then((response) => {
      return response.data.learning_categories

    })
  }
  async delete(targetId: number): Promise<LearningCategory[]> {
    return axios.delete(LearningCategoryRepository.DOMAIN + "v1/learning_categories/" + targetId, { params: { project_id: this.projectId } }).then((response) => {
      return response.data.learning_categories
    })
  }
  async sort(targetId: number, dependId: number, type: string): Promise<LearningCategory[]> {
    return axios.post(LearningCategoryRepository.DOMAIN + "v1/learning_categories/" + targetId + "/sort", { project_id: this.projectId, depend_id: dependId, type: type }).then((response) => {
      return response.data.learning_categories

    })
  }
}
